import React from 'react'
import './campaign.css'

const Campaign:React.FC = () => {
  return (
    <div className='campaign__container'>
        <div className="campaign__wrapper">
            <img src="/img/widgets/banner.png" alt=''/>
        </div>
    </div>
  )
}

export const VideoBanner:React.FC = () => {
  return (
    <div className='campaign__container'>
        <div className="campaign__wrapper">
            <img src="/img/widgets/video.png" alt=''/>
        </div>
    </div>
  )
}

export const SmallWideBanner:React.FC = () => {
  return (
    <div className='campaign__container'>
        <div className="campaign__wrapper">
            <img src="/img/widgets/two-collum-wide.png" alt=''/>
        </div>
    </div>
  )
}

export default Campaign