import React, { FC } from 'react'
import { Widget, widgetNames } from '../../widgetList/widgetList'
import FromForSlider from './FormForSlider'
import FormForBanner from './FormForBanner'
import FormForThreeCollumBanner from './FormForThreeCollum'
import FormForPopulerProduct from './FormForPopulerProduct'
import FomrForTile from './FormForTitle'
import FormForCategories from './FormForCategories'
import FormForSingleCollumPromotion from './FormForSingleCollumPromotion'
import FormForDoubleCollumPromotion from './FormForDoubleCollumPromotion'
import FormForProductRow, { FomrForProductRow2 } from './FormForProductRow'
import FormForBarndList from './FormForBrandList'
import FormForSellerList from './FormForSellerList'
import FormForNewArrival from './FormForNewArrival'
import FormForProductSlider from './FormForProductSlider'
import FormForTodaysDeal from './FormForTodaysDeal'
import FormForHighlightShop from './FormForHighlightShop'
import FormForBestSellingProdcuts from './FormForBestSellingProducts'
import FormForSingleProductSlider from './FormForSingleProductSlider'
import FormForElectronicsApplience from './FormForElectronicsApplience'
import FormForSellerProfile from './FormForSellerProfile'
import FormForContentEditor from './FormForContentEditor'
import FormForProductsLimit from './FormForProductsLimit'
import FormForInDemand from './FormForInDemand'
import FormForVideoSection from './FormForVideoSection'
import FormForSmallWideBanner from './FormForSmallWideBanner'
import FormForBanners from './FormForBanners'
import FomrForSpacing from './FormForSpacing'

interface Props {
    choosenItem: Widget
    onUpdate: (w: Widget) => void
}

const WidgetForm:FC<Props> = ({choosenItem, onUpdate}) => {
  return (
    <>
        {
            choosenItem.name === 'slider' ? <FromForSlider onUpdate={onUpdate} choosenItem={choosenItem}/> : 
            choosenItem.name === 'banner' ? <FormForBanner onUpdate={onUpdate} choosenItem={choosenItem}/> : 
            choosenItem.name === '3-collum-images' ? <FormForThreeCollumBanner onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === 'populer-product' ? <FormForPopulerProduct onUpdate={onUpdate} choosenItem={choosenItem}/> : 
            choosenItem.name === 'title' ? <FomrForTile onUpdate={onUpdate} choosenItem={choosenItem}/> : 
            choosenItem.name === 'categories' ? <FormForCategories onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === 'one-collum-add' ? <FormForSingleCollumPromotion onUpdate={onUpdate} choosenItem={choosenItem}/> : 
            choosenItem.name === 'two-collum-add' ? <FormForDoubleCollumPromotion onUpdate={onUpdate} choosenItem={choosenItem}/> : 
            choosenItem.name === 'product-row' ? <FormForProductRow onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === 'brand-list' ? <FormForBarndList onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === 'seller-list' ? <FormForSellerList onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === 'new-arrival' ? <FormForNewArrival onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === 'product-slider' ? <FormForProductSlider onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === 'todays-deal' ? <FormForTodaysDeal onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === 'highlighted-shop' ? <FormForHighlightShop onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === 'best-selling' ? <FormForBestSellingProdcuts onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === 'single-product' ? <FormForSingleProductSlider onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === 'electronics-applience' ? <FormForElectronicsApplience onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === 'seller-profile' ? <FormForSellerProfile onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === 'content-editor' ? <FormForContentEditor onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === 'product-row-limit' ? <FormForProductsLimit onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === 'in-demand' ? <FormForInDemand onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === widgetNames.lastVisitedProducts ? <FomrForProductRow2 onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === widgetNames.infiniteProductRow ? <FormForProductsLimit widgetName={widgetNames.infiniteProductRow} title='Infinite Product Row' onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === widgetNames.videoSection ? <FormForVideoSection widgetName={widgetNames.infiniteProductRow} onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === widgetNames.smallWideBanner ? <FormForSmallWideBanner onUpdate={onUpdate} choosenItem={choosenItem}/> : 
            choosenItem.name === widgetNames.banners ? <FormForBanners onUpdate={onUpdate} choosenItem={choosenItem}/> :
            choosenItem.name === widgetNames.padding ? <FomrForSpacing onUpdate={onUpdate} choosenItem={choosenItem}/> :
            null
        }
    </>
  )
}

export default WidgetForm