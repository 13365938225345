import React, { FC, useEffect, useState } from 'react'
import { Widget, widgetNames } from '../../../widgetList/widgetList'
import { Panel } from '../TopPanel'
import ProductsDefaultSettings from '../ProductsDefaultSettings'

interface Props {
    choosenItem: any
    onUpdate: (w: Widget) => void
}

const FomrForProductRow:FC<Props> = ({choosenItem, onUpdate}) => {

    const [active, setActive] = useState(true)
    const [productLimit, setProductLimit] = useState<string>('')

    const [settings, setSettings] = useState<any>(choosenItem?.data?.settings as any);

    useEffect(()=>{
        setProductLimit(choosenItem.productLimit || '')
    },[choosenItem])

    const handleProductLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
        const limit:string = e.target.value.replace(/\D/g, '')
        setProductLimit(limit)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const titleObj = {
            name: 'product-row',
            active: active,
            productLimit: productLimit,
            wType: choosenItem.wType,
            id: choosenItem.id,
            data : {
                settings: settings,
            },
        }
        onUpdate(titleObj as any)
    }
  return (
    <>
    { choosenItem.name === 'product-row' ?
        <form onSubmit={handleSubmit} className='mainSliderForm__container'>
            <h1 className='title capitalize'>product row</h1>
            {/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}
            <div>
                {/* <div className='grid grid-cols-2 gap-4'>
                    <div className='capitalize tracking-wide col-span-1 items-center flex'>Product Limit: </div>
                    <input className='col-span-1' value={productLimit} onChange={handleProductLimit} type="text" />
                </div> */}

                <ProductsDefaultSettings
                    settings={settings}
                    onChange={(key, value) => {
                        setSettings({
                            ...settings,
                            [key]: value,
                        })
                    }}
                />
            </div>
            <div>
                <button className='mainSliderForm__btn'>save</button>
            </div>
        </form>
        : <div className='flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52'>Please check widget name!</div>
    }
    </>
  )
}

export const FomrForProductRow2:FC<Props> = ({choosenItem, onUpdate}) => {

    const [active, setActive] = useState(true)
    const [productLimit, setProductLimit] = useState<string>('')
    const [settings, setSettings] = useState<any>(choosenItem?.data?.settings as any);

    useEffect(()=>{
        setProductLimit(choosenItem.productLimit || '')
    },[choosenItem])

    const handleProductLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
        const limit:string = e.target.value.replace(/\D/g, '')
        setProductLimit(limit)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const titleObj = {
            name: widgetNames.lastVisitedProducts,
            active: active,
            productLimit: productLimit,
            wType: choosenItem.wType,
            id: choosenItem.id,
            data : {
                settings: settings,
            },
        }
        onUpdate(titleObj as any)
    }
  return (
    <>
    { choosenItem.name === widgetNames.lastVisitedProducts ?
        <form onSubmit={handleSubmit} className='mainSliderForm__container'>
            <h1 className='title capitalize'>{choosenItem.name}</h1>
            {/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}
            <div>
                {/* <div className='grid grid-cols-2 gap-4'>
                    <div className='capitalize tracking-wide col-span-1 items-center flex'>Product Limit: </div>
                    <input className='col-span-1' value={productLimit} onChange={handleProductLimit} type="text" />
                </div> */}

                <ProductsDefaultSettings
                    settings={settings}
                    onChange={(key, value) => {
                        setSettings({
                            ...settings,
                            [key]: value,
                        })
                    }}
                />
            </div>
            <div>
                <button className='mainSliderForm__btn'>save</button>
            </div>
        </form>
        : <div className='flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52'>Please check widget name!</div>
    }
    </>
  )
}

export default FomrForProductRow