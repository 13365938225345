import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { PageCategory, Widget } from '../../../widgetList/widgetList'
import CampaignSelector from '../../CampaignSelector'
import LogicBuilder from '../../LogicBuilder'
import LogicalBuilder from '../../LogicBuilder/LogicalBuilder'
import { LinkData } from '../LinkForm'
import TopPanel, { Panel } from '../TopPanel'
import ProductsDefaultSettings from '../ProductsDefaultSettings'

interface Props {
    choosenItem: any // type: Widget
    onUpdate: (w: Widget) => void
}

const FormForProductSlider:FC<Props> = ({choosenItem, onUpdate}) => {
    const choosenAllImages = useSelector((state: RootState) => state.showImagePopUp.images)
    const customProductIds = useSelector((state: RootState) => state.logicBuilder.setting)

    const [active, setActive] = useState<boolean>(choosenItem.active)

    const [featuredImage, setFeatiredImage] = useState('')
    const [title, setTitle] = useState('')
    const [link, setLink] = useState<LinkData>({} as LinkData)
    const [fetcturedImagePosition, setFetcturedImagePosition] = useState('left')
    const [sourceType, setSourceType] = useState('custom')
    const [isSlider, setIsSlider] = useState<boolean>(!!choosenItem.data?.isSlider)

    const [selectedCampaign, setSelectedCampaign] = useState<PageCategory | null>(null);

    const [panel, setPanel] = useState<Panel>('main');

    const [colProductLimit, setColProductLimit] = useState<number>(choosenItem?.data?.settings?.productLimit)
    const [py, setPy] = useState<number>(choosenItem?.data?.settings?.py)

    const [containerType, setContainerType] = useState<'full' | 'box'>((choosenItem as any)?.data?.settings?.containerType || 'box');
    const [settings, setSettings] = useState<any>(choosenItem?.data?.settings as any);

    useEffect(()=> {
        const customLenght: any = choosenItem?.data?.setting?.custom?.length
        if(customLenght > 0){
            setSourceType('custom')
        }else {
            setSourceType('logical')
        }
    },[choosenItem])

    useEffect(()=>{
        setFeatiredImage(choosenItem?.featuredImage || '')
        setFetcturedImagePosition(choosenItem?.fetcturedImagePosition || '')
        setLink(choosenItem?.fetchuredImageUrl as LinkData || {} as LinkData)
        setTitle(choosenItem.title || '')
        setIsSlider(!!choosenItem.data?.isSlider)
    },[choosenItem])

    useEffect(()=>{
        choosenAllImages.map((item: any) =>{
            return (
                setFeatiredImage(item.path)
            )
        })
    },[choosenAllImages])

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) =>{
        e.preventDefault()
        console.log('customProductIds =', customProductIds)
        const widgetObj: any = {
            name: 'product-slider',
            active: active,
            data : {
                images: [],
                setting: sourceType === 'custom' ? { custom: customProductIds.custom,  logical: {} } : { custom: [],  logical: customProductIds.logical },
                isSlider: isSlider,
                selectedCampaign: selectedCampaign || choosenItem?.data?.selectedCampaign || null,
                settings: {
                    productLimit: colProductLimit,
                    containerType: containerType,
                    py: py,
                    ...settings,
                }
            },
            wType: choosenItem.wType,
            id: choosenItem.id,
        }
        console.log('widgetObj =>', widgetObj)
        onUpdate(widgetObj)
    }
    return (
        <>
        { choosenItem.name === 'product-slider' ?
            <form onSubmit={handleSubmit} className='mainSliderForm__container'>
                <h1 className='title capitalize'>products slider</h1>

                <TopPanel 
                    panel={panel}
                    onChange={name => setPanel(name)}
                />
                {/* <div className='checkbox'>
                    <div>Is Active: </div>
                    <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
                </div> */}
                <div>
                    <div className='flex items-center gap-4'>
                        <div onClick={()=> setIsSlider(!isSlider)} className='flex items-center gap-2 cursor-pointer'>
                            <div>Is Slider : </div>
                            <div className={`w-5 h-5 rounded-md ${isSlider ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                        </div>
                    </div>
                </div>
                
                {
                    panel === 'main' ? (
                        <div>
                        <div className='flex flex-col items-start gap-4 mt-3 pt-2 border-t'>
                            <CampaignSelector
                                initialCampaign={choosenItem?.data?.selectedCampaign || null} 
                                onUpdate={(data) => {
                                    setSelectedCampaign(data);
                                }} 
                            />
                        </div>

                        <div className='flex flex-col items-start gap-4'>
                            <div>Source type : </div>
                            <div className='flex items-center gap-4'>
                                <div onClick={()=> setSourceType('logical')} className='flex items-center gap-2 cursor-pointer'>
                                    <div className={`w-5 h-5 rounded-md ${sourceType === 'logical' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                    <div>Logical</div>
                                </div>
                                <div onClick={()=> setSourceType('custom')} className='flex items-center gap-2 cursor-pointer'>
                                    <div className={`w-5 h-5 rounded-md ${sourceType === 'custom' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                    <div>Custom</div>
                                </div>
                            </div>
                        </div>

                        <LogicBuilder sourceType={sourceType} choosenItem={choosenItem}/>

                    </div>
                    ) : (
                        <div className='flex flex-col items-start gap-4 mt-3 pt-2 border-t'>
                            <div className='flex items-center gap-4'>
                                <div>Product Limit : </div>
                                <input className='w-20' type="number" value={colProductLimit} onChange={(e)=> setColProductLimit(Number(e.target.value))}/>
                            </div>

                            <div className='flex flex-col mb-2'>
                                <div>Image container: </div>
                                <div className='[&>input]:!w-4 [&>input]:mx-2'>
                                    <input type="radio" id="b-type-full" name="imageContainer" value="full" 
                                        onChange={(e) => setContainerType(e.target.value as 'full' | 'box')}
                                        checked={containerType === 'full'}
                                    />
                                    <label htmlFor="b-type-full">Full</label>

                                    <input type="radio" id="b-type-box" name="imageContainer" value="box" 
                                        onChange={(e) => setContainerType(e.target.value as 'full' | 'box')}
                                        checked={containerType === 'box'}
                                    />
                                    <label htmlFor="b-type-box">Box</label>
                                </div>
                            </div>

                            <div className='flex flex-col mb-2'>
                                <div>Full Width Spacing (px) : </div>
                                <input className='w-20' type="number" value={py} onChange={(e)=> setPy(Number(e.target.value))}/>
                            </div>


                            <ProductsDefaultSettings 
                                settings={settings}
                                onChange={(key, value) => {
                                    setSettings({
                                        ...settings,
                                        [key]: value,
                                    })
                                }}
                            />
                        </div>
                    )
                }

                <div>
                    <button className='mainSliderForm__btn'>save</button>
                </div>
            </form>
            : <div className='flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52'>Please check widget name!</div>
        }
        </>
    )
}

export default FormForProductSlider